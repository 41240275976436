/* src/Header.css */
.header {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #3f51b5;
  color: white;
}

.logo-container {
  flex-shrink: 0;
}

.logo {
  width: 100px;
  height: 100px;
}

.portfolio-logo {
  width: 100px;
  height: 100px;
  background: url('../public/images/logo.png') no-repeat center; /* Adjusted path */
  background-size: contain;
  margin-right: 20px;
}

.title-container {
  margin-left: 20px;
  flex-grow: 1;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.subtitle {
  font-size: 14px;
}

.navbar {
  display: flex;
}

.navbar a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.navbar a:hover {
  text-decoration: underline;
}

/* src/App.css */
.App {
  text-align: center;
}

.content {
  padding: 20px;
}

.service-card {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.footer {
  padding: 20px;
  background-color: #3f51b5;
  color: white;
}

.footer nav {
  margin-top: 10px;
}

.footer a {
  color: white;
  text-decoration: none;
  margin: 0 5px;
}
